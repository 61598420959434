import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const References = () => {
  return (
    <Layout>
      <Seo title="References | Resistant.AI" />
      references
    </Layout>
  )
}

export default References
